import ApiService from './api.service';

const ModuloService = {
	buscarModulosUsuarioLogado: async () => {
		const response = await ApiService.get('/api/modulo');
		return response.data;
	},
};

export default ModuloService;
