import Vue from 'vue';
import VueRouter from 'vue-router';
import SessaoService from '@/services/sessao.service';
import Login from '@/views/Login.vue';
import LoginError from '@/views/LoginError.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
	},
	{
		path: '/app',
		name: 'App',
		component: Login,
		meta: {
			mostrarOpcoesApp: true,
		},
	},
	{
		path: '*',
		redirect: '/',
	},
	{
		path: '/login-error',
		name: 'LoginError',
		component: LoginError,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const authenticate = await SessaoService.authenticate();
	if (to.name === 'LoginError') {
		next();
	}
	if (to.name === 'Login') {
		if (authenticate) {
			next({
				path: '/app',
			});
		} else {
			next();
		}
	} else if (authenticate) {
		next();
	} else {
		next({
			path: '/',
		});
	}
});

export default router;
