import Vue from 'vue';
import VueWait from 'vue-wait';

import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
import '@/content/styles/element-variables.scss';
import '@/content/styles/main.scss';
import '@mdi/font/scss/materialdesignicons.scss';

import App from '@/App.vue';
import router from '@/router';

import ApiService from '@/services/api.service';

Vue.config.productionTip = false;

Vue.use(ElementUI);
locale.use(lang);

ApiService.init();

new Vue({
	router,
	wait: new VueWait({
		useVuex: true,
	}),
	render: (h) => h(App),
}).$mount('#app');
