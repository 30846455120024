import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Loading } from 'element-ui';
import config from '@/constants/config';

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = config.BASE_URL_API_AXIOS;
		let controlLoading = 0;

		axios.interceptors.request.use((requestConfig) => {
			controlLoading += 1;

			if (controlLoading === 1) {
				Loading.service();
			}
			return requestConfig;
		}, (error) => {
			Loading.service().close();
			return Promise.reject(error);
		});

		axios.interceptors.response.use((response) => {
			controlLoading -= 1;
			if ((controlLoading) === 0) {
				Loading.service().close();
			}

			return response;
		}, (error) => {
			Loading.service().close();
			controlLoading -= 1;
			return Promise.reject(error);
		});
	},

	async query(resource, params) {
		try {
			return await Vue.axios
				.get(resource,

					{
						params,
					});
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async get(resource) {
		try {
			return await Vue.axios.get(`${resource}`);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async post(resource, params) {
		try {
			return await Vue.axios.post(`${resource}`, params);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async update(resource, slug, params) {
		try {
			return await Vue.axios.put(`${resource}/${slug}`, params);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async put(resource, params) {
		try {
			return await Vue.axios.put(`${resource}`, params);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async delete(resource) {
		try {
			return await Vue.axios.delete(resource);
		} catch (error) {
			const result = { message: this.genericErrorHandling(error) };

			return Promise.reject(result);
		}
	},

	async forceDownload(resource, payload) {
		const fileLink = document.createElement('a');
		try {
			Loading.service();
			const response = await Vue.axios.post(resource, payload, { responseType: 'blob' });

			const fileURL = window.URL.createObjectURL(new Blob([response.data]));
			fileLink.href = fileURL;

			const filenameRegex = /filename=['"]?([^;\r\n"']*)['"]?;?/;
			const contentDisposition = response.headers['content-disposition'];

			fileLink.setAttribute('download', filenameRegex.exec(contentDisposition)[1]);
			document.body.appendChild(fileLink);

			fileLink.click();
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		} finally {
			Loading.service().close();
			fileLink.remove();
		}
	},

	genericErrorHandling() {
		// const { message } = error;
		// switch (error.response.status) {
		// 	case 400:
		// 		return this.handlingStatus(error);
		// 	case 403:
		// 		this.handling403(error.response.data);
		// 		break;
		// 	case 404:
		// 		return this.handlingStatus(error);
		// 	case 409:
		// 		return this.handlingStatus(error);
		// 	case 500:
		// 		// console.log(error.response);
		// 		break;
		// 	default:
		// 		break;
		// }

		// return message;
	},

};

export default ApiService;
