<template lang="pug">
</template>

<script>
import Vue from 'vue';

export default {
	created() {
		Vue.prototype.$notify({
			title: 'Atenção',
			message: 'Você não tem permissão para acessar essa página.',
			type: 'error',
		});
		this.$router.replace('/');
	},
};
</script>
