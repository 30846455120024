<template lang="pug">
div
	el-row
		el-col(:span='24')
			.grid-content.background
				el-col(:span='12')
					.grid-content.content
						el-col(:span='12')
							.grid-content.left-content
								img.logo(src='@/assets/logo-tela-login.png')
						el-col(:span='12')
							.grid-content.right-content(v-if='!mostrarOpcoesApp')
								p.text Seja bem vindo
								el-button(@click.native='login' type='success') Entrar
							.grid-content.right-content(v-else)
								p.text Selecione o módulo
								el-col(:span='18')
									el-row(v-for='destino in appsDestino', :key='destino.nome')
										el-card.card-hover(@click.native='goto(destino.url)')
											el-row(:gutter='2', type='flex', justify='center')
												el-col(:span='4')
													i.mdi(:class='destino.icon')
												el-col(:span='16')
													span.profile-description {{destino.nome}}

</template>

<script>

import config from '@/constants/config';
import ModuloService from '@/services/modulo.service';

export default {
	data() {
		return {
			alertError: false,
			alertWarning: false,
			mostrarOpcoesApp: false,
			isAdministrador: false,
			appsDestino: [],
		};
	},
	async mounted() {
		if (this.$route.meta.mostrarOpcoesApp) {
			this.mostrarOpcoesApp = true;
		}
		this.appsDestino = await ModuloService.buscarModulosUsuarioLogado();
	},
	methods: {
		goto(url) {
			window.location.href = url;
		},
		login() {
			this.goto(config.URL_LOGIN_AZURE_AD);
		},
	},
};
</script>

<style lang="scss" scoped>

@import "@/content/styles/colors.scss";

.background {
	background: $secondary;
	height: 100vh;
}
.content {
	background-color: $primary;
	border-radius: 12px;
	border: 14px solid $primary;
}
.left-content {
	height: 50vh;
}
.logo {
	width: 46%;
	margin-right: 14px;
}
.right-content {
	background: $white;
	height: 50vh;
	border-radius: 12px !important;
	display: flex;
	flex-direction: column;
}
.grid-content {
	display: flex;
	justify-content: center;
	align-items: center;
}
.text {
	font-family: "Roboto", sans-serif;
	font-size: 28px;
	color: $font;
	text-align: center;
	margin: 0px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.el-button {
	width: 50%;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
}
a {
	padding-top: 6px;
	text-decoration: none;
	color: $link;
	font-size: 14px;
}
.el-alert {
	width: 50%;
}

</style>
