import ApiService from './api.service';

const UsuarioService = {
	authenticate: async () => {
		const response = await ApiService.get('/api/authenticate');
		return response.data;
	},
};

export default UsuarioService;
